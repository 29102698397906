<template>
    <b-nav-item-dropdown text="Dashboard" right>
        <b-dropdown-item 
            v-if="visibleFor(['teacher', 'schoolAdmin', 'districtAdmin'])"
            v-tooltip="''"
            :href="`${baseUrl}/`">
            <font-awesome-icon class="text-primary nav-icon mr-2" :icon="['fad', 'grid-2']" /> Overview
        </b-dropdown-item>
        <b-dropdown-item 
            v-if="visibleFor(['student', 'teacher', 'schoolAdmin', 'districtAdmin'])"
            v-tooltip="''"
            :href="`${baseUrl}/assignments`">
            <font-awesome-icon class="text-primary nav-icon mr-2" :icon="['fad', 'bars-progress']" /> Assignments
        </b-dropdown-item>
        <b-dropdown-item 
            v-if="visibleFor(['teacher', 'schoolAdmin', 'districtAdmin'])"
            v-tooltip="''"
            :href="`${baseUrl}/analytics`">
            <font-awesome-icon class="text-primary nav-icon mr-2" :icon="['fad', 'chart-simple']" /> Analytics
        </b-dropdown-item>
        <b-dropdown-item 
            v-if="visibleFor(['teacher', 'schoolAdmin', 'districtAdmin'])"
            v-tooltip="''"
            :href="`${baseUrl}/roster`">
            <font-awesome-icon class="text-primary nav-icon mr-2" :icon="['fad', 'address-book']" /> Roster
        </b-dropdown-item>
        <b-dropdown-item 
            v-if="visibleFor(['schoolAdmin'])"
            v-tooltip="''"
            :href="`${baseUrl}/sections`">
            <font-awesome-icon class="text-primary nav-icon mr-2" :icon="['fad', 'grid-2']" /> Users
        </b-dropdown-item>
        <b-dropdown-item 
            v-if="visibleFor(['teacher']) || (visibleFor(['schoolAdmin']) && user.access_type == 'Native')"
            v-tooltip="''"
            :href="`${baseUrl}/sections`">
            <font-awesome-icon class="text-primary nav-icon mr-2" :icon="['fad', 'list']" /> Sections
        </b-dropdown-item>
        <b-dropdown-item 
            v-if="visibleFor(['teacher', 'schoolAdmin', 'districtAdmin'])"
            v-tooltip="''"
            :href="`${baseUrl}/interventions`">
            <font-awesome-icon class="text-primary nav-icon mr-2" :icon="['fad', 'list']" /> Interventions
        </b-dropdown-item>
        <b-dropdown-item 
            v-if="visibleFor(['teacher', 'schoolAdmin', 'districtAdmin'])"
            v-tooltip="''"
            :href="`${baseUrl}/labels`">
            <font-awesome-icon class="text-primary nav-icon mr-2" :icon="['fad', 'tags']" /> Labels
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item 
            v-if="visibleFor(['teacher', 'schoolAdmin', 'districtAdmin']) && user.access_type == 'Native'"
            v-tooltip="''"
            :href="`${baseUrl}/myAccount`">
            <font-awesome-icon class="text-primary nav-icon mr-2" :icon="['fad', 'gear']" /> My Account</b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item href="/logout">
            <font-awesome-icon class="text-primary nav-icon mr-2" :icon="['fad', 'right-from-bracket']" /> Log Out
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
    import {BDropdown, BDropdownItem, BDropdownDivider} from 'bootstrap-vue'
    
    export default {
        computed: {
            baseUrl() {
                return Nextgenmath.routes.dashboard_url;
            },
            user() {
                return this.$page.props.user;
            },
        },
        methods: {
            visibleFor(roles) {
                return roles.includes(this.user.role);
            },
        },
        components: {
            BDropdown,
            BDropdownItem,
            BDropdownDivider,
        },
    }
</script>

<style scoped lang="scss">
    .dropdown {
        &.show {
        border-bottom: none;
    
            &::v-deep .nav-link.dropdown-toggle {
                font-weight: 450;
                border-bottom: 1px solid #c4c4c4;
            }
        }

        ::v-deep .dropdown-menu {
            margin: 0 auto;
            padding: 1rem;
            font-size: 16px;
            box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
            border-top: 4px solid #fff;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            .dropdown-item {
                padding: 0.5rem;
                padding-left: 1rem;
            }

            .dropdown-item:hover,
            .dropdown-item:active,
            .dropdown-item:hover svg,
            .dropdown-item:active svg {
                background: #3987d8 !important;
                color: #fff !important;
            }

            .nav-icon {
                font-size: 1.3rem;
                vertical-align: bottom;
            }
        }

        @media (max-width: 992px) {
            /* rules here apply to devices from 0px to 992px (inclusive) */
            &.show {
                border-bottom: none;

                ::v-deep .dropdown-menu {
                    box-shadow: none;
                    border: none;
                }

                ::v-deep .nav-link.dropdown-toggle {
                    width: 85vw;
                    left: calc(50% - (85vw / 2));
                    font-weight: 600;
                    border-bottom: 1px solid #757575;
                }
            }
        }
    }
</style>
